var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", [
    _c(
      "div",
      { staticClass: "text-right", attrs: { slot: "header" }, slot: "header" },
      [
        _c(
          "router-link",
          { attrs: { to: { name: "singleFaq", params: { id: "new" } } } },
          [
            _c("BaseButton", { attrs: { type: "green", gradient: "" } }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("addNewItem", { item: _vm.$tc("faq") })) +
                  " "
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("BaseDraggableList", {
          attrs: { items: _vm.items, model: "faq", "supports-photo": false },
          on: { updatePosition: _vm.updatePosition, remove: _vm.remove },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }